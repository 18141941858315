<template>
  <div class="app__wrapper">
    <div class="app__inner pt-3 d-flex flex-column" v-if="hasimage">
      <div class="d-flex flex-column flex-grow-1 text-center">
        <div class="bg-blue d-flex flex-column flex-grow-1 mx-3 text-center">
          <img src="./assets/images/jabulani-logo2.svg" class="mb-4 mt-3" alt="Logo" style="height: 13vh" />
          <div class="crop__frame">
            <cropper
              ref="cropper"
              :src="image.src"
              imageRestriction='none'
              :stencil-props="{
                previewClass: 'circle-cropper__preview',
              }"
              class="circle-cropper flex-grow-1"
              :class="{'frame-0':selectedFrame == 0, 'frame-1':selectedFrame == 1}"
              stencil-component="circle-stencil"
            />
          </div>
          <img src="./assets/images/smollen.svg" class="mb-4 mt-4" alt="Logo" style="height: 3vh">
        </div>
        <select id="selector" v-model="selectedFrame" name="" class="text-light upload-example__button mx-auto my-3" style="min-width: 240px;">
          <option value="0">LET'S GET THE JAB!</option>
          <option value="1">FULLY VACCINATED!</option>
        </select>
      </div>
      <div class="action__wrapper flex-grow-0">
        <div class="upload-example__buttons-wrapper my-4">
          <div class="d-none d-md-block"></div>
          <div class="d-none d-md-block"></div>
          <button class="upload-example__button" @click="reset()">
            Reset
          </button>
          <button v-if="image.src" class="upload-example__button" @click="crop()">Save</button>
          <div class="d-none d-md-block"></div>
          <div class="d-none d-md-block"></div>
        </div>
      </div>
    </div>
    <div class="app__upload d-flex flex-column align-items-center justify-content-center" v-if="!hasimage">
      <img src="./assets/images/jabulani-logo.svg" class="h-50 mb-4" alt="Logo">

      <button class="upload-example__button mt-4" @click="$refs.file.click()" style="min-width: 260px">
        <input ref="file" type="file" accept="image/*" @change="loadImage($event)" />
        Upload your image
      </button>
      <div class="upload-example__cropper-wrapper">
        <div class="upload-example__file-type" v-if="image.type">
          {{ image.type }}
        </div>
      </div>
    </div>
    <div class="ios__frame" :class="{ 'ios__frame--visible' : showIosDownload }">
      <img id="profileImage" class="ios__image" src="" alt="Tap and download" />
        <h3 style="font-size: 17px; line-height: 22px">Tap and hold on the above image to save it to your&nbsp;photos.</h3>
      <button @click="() => showIosDownload = false" class="ios__button upload-example__button" title="Close Overlay">
        <span>Back</span>
      </button>
      <div></div>
    </div>
    <div class="hide-me">
      <img src="./assets/images/filter-full--800.png" alt="">
      <img src="./assets/images/filter-jab--800.png" alt="">
    </div>

  </div>
</template>

<script>
import { CircleStencil, Cropper } from 'vue-advanced-cropper'
import mergeImages from 'merge-images'
import 'vue-advanced-cropper/dist/style.css';

function resizedataURL(datas, wantedWidth, wantedHeight) {

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async function(resolve) {
      // We create an image to receive the Data URI
      var img = document.createElement('img')
      // When the event "onload" is triggered we can resize the image.
      img.onload = function()
      {
          // We create a canvas and get its context.
          var canvas = document.createElement('canvas')
          var ctx = canvas.getContext('2d')

          // We set the dimensions at the wanted size.
          canvas.width = wantedWidth
          canvas.height = wantedHeight

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)

          let dataURI = canvas.toDataURL()

          // This is the return of the Promise
          resolve(dataURI)
      }
      // We put the Data URI in the image's src attribute
      img.src = datas
  })
}

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
	const byteArray = new Uint8Array(file).subarray(0, 4);
	let header = '';
	for (let i = 0; i < byteArray.length; i++) {
		header += byteArray[i].toString(16);
	}
	switch (header) {
		case '89504e47':
			return 'image/png';
		case '47494638':
			return 'image/gif';
		case 'ffd8ffe0':
		case 'ffd8ffe1':
		case 'ffd8ffe2':
		case 'ffd8ffe3':
		case 'ffd8ffe8':
			return 'image/jpeg';
		default:
			return fallback;
	}
}

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Cropper, CircleStencil
  },
  data() {
    return {
      showIosDownload: false,
      selectedFrame: 0,
      hasimage: false,
			image: {
				src: null,
				type: null,
			},
    }
  },
  methods: {
    async crop() {
			const { canvas } = this.$refs.cropper.getResult();

      // eslint-disable-next-line no-undef
      const durl = canvas.toDataURL((durl) => resolve(durl))

      const newDataUri = await resizedataURL(durl, 800, 800)
      const whiteImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyAAAAMgAQMAAADhvpQrAAAAA1BMVEX///+nxBvIAAAAcUlEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOD24JAAAAAAQND/126wAwAAAAAAAAAAAAAAAAAAAAAAAABLO68AAdqIA14AAAAASUVORK5CYII='

      // eslint-disable-next-line no-unused-vars
      const isIos = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

      let frameUrl = 'filter-full--800.png'
      if (this.selectedFrame == '0') {
        frameUrl = 'filter-jab--800.png'
      }

      mergeImages([{ src: whiteImg, x: 0, y: 0 }, { src: newDataUri, x: 0, y: 0 }, { src: `img/${ frameUrl }`, x: 0, y: 0 }], {
        width: 800,
        height: 800
      }).then(b64Data => {
        if (isIos) {
          // eslint-disable-next-line no-console
          let imgpic = document.getElementById('profileImage')
          imgpic.src = b64Data
          this.showIosDownload = true
          // console.log('Show the image to Download')
        } else {
          const a = document.createElement('a')
          a.href = b64Data
          a.download = 'download'
          a.download = a.download.replace('.jpeg', '.png')
          a.download = a.download.replace('.jpg', '.png')
          a.download = a.download.replace('.webp', '.png')
          a.download = a.download.replace('.gif', '.png')
      
          const clickHandler = () => {
            setTimeout(() => {
              a.removeEventListener('click', clickHandler)
            }, 150)
          }
      
          a.addEventListener('click', clickHandler, false)
          a.click()
          
        }
      })
		},
		reset() {
      this.hasimage = false
			this.image = {
				src: null,
				type: null,
			};
		},
		loadImage(event) {
			// Reference to the DOM input element
			const { files } = event.target;
			// Ensure that you have a file before attempting to read it
			if (files && files[0]) {
				// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src);
				}
				// 2. Create the blob link to the file to optimize performance:
				const blob = URL.createObjectURL(files[0]);
				// 3. The steps below are designated to determine a file mime type to use it during the
				// getting of a cropped image from the canvas. You can replace it them by the following string,
				// but the type will be derived from the extension and it can lead to an incorrect result:
				//
				// this.image = {
				//    src: blob;
				//    type: files[0].type
				// }
				// Create a new FileReader to read this image binary data
				const reader = new FileReader();
				// Define a callback function to run, when FileReader finishes its job
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.image" refers to the image of Vue component
					this.image = {
						// Read image as base64 and set it as src:
						src: blob,
						// Determine the image type to preserve it during the extracting the image from canvas:
						type: getMimeType(e.target.result, files[0].type),
					};
          this.hasimage = true
				};
				// Start the reader job - read file as a data url (base64 format)
				reader.readAsArrayBuffer(files[0]);
			}
		},
	},
	destroyed() {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (this.image.src) {
			URL.revokeObjectURL(this.image.src);
		}
	},
}
</script>

<style lang="scss">
@import "~@/assets/scss/styles.scss";

.crop__frame {
  height: 43vh;
  flex-grow: 1;
  position:relative;
}

.hide-me {
  overflow: hidden;
  height:0px;
}

.ios__frame {
	position: fixed;
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	top:20px;
	left:20px;
	right:20px;
	bottom:20px;
	background-color: #fff;
	overflow: auto;

	opacity:0;
	pointer-events:none;
	transform: translateY(10%);

	transition: transform 0.2s, opacity 0.2s;
}
.ios__frame--visible {
	opacity:1;
	transform: translateY(0);
	pointer-events: auto;
}
.ios__frame h3 {
	text-align: center;
	display: block;
	font-weight: bold;
	max-width: 300px;
}
img.ios__image {
	width: 90%;
	max-width: 450px;
	max-height: 450px;
	margin: 20px auto 20px auto;
}
.ios__button {
	margin: 0 auto;
}

.vue-circle-stencil:after {
  content:'';
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height: 100%;
  background-size: cover;
  pointer-events: none;
  .frame-0 & {
    background-image: url(./assets/images/filter-jab--360.png);
  }
  .frame-1 & {
    background-image: url(./assets/images/filter-full--360.png);
  }
}


.action__wrapper {
  background-image: url(./assets/images/save-tile.png);
  background-repeat: repeat-x;
  background-size: auto 100%;
}
select#selector {
  -webkit-appearance: none;
  -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")!important;
    background-color: #91489e;
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;

}

.bg-blue {
    background-color: #01BBF4;
}

.circle-cropper {
	width: 100%;
	background: #222;
	&__preview {
		border: solid 1px rgba(white, 0.15);
	}
}

.app {
  &__wrapper {
    display:block;
    min-height: 100vh;
    height: 100%;
    position: relative;
    background-image: url(./assets/images/tile.png);
    padding: 25px 0;
  }
  &__inner {
    background-color: #fff;
    margin: 0 25px;
    min-height: calc(100vh - 50px);
  }
  &__upload {
    position: absolute;
    background-color: #01BBF4;
    top:25px;
    left:25px;
    width: calc(100vw - 50px);
    min-height: calc(100vh - 50px);
  }
}

.upload-example {
	&__buttons-wrapper {
		display: flex;
		justify-content: space-around;
		margin: 17px 20px 0 20px;
	}
	&__button {
		border: none;
		outline: solid transparent;
		color: white;
		font-size: 16px;
		padding: 8px 20px;
		background: #91489e;
    border-radius: 10px;
    font-weight: 600;
    width:40%;
    max-width: 250px;
		cursor: pointer;
		transition: background 0.5s;
		&:hover,
		&:focus {
			background: darken(#91489e, 15%);
		}
		input {
			display: none;
		}
	}
	&__file-type {
		position: absolute;
		top: 20px;
		left: 20px;
		background: #0d0d0d;
		border-radius: 5px;
		padding: 0px 10px;
		padding-bottom: 2px;
		font-size: 12px;
		color: white;
	}
}

.vue-advanced-cropper__background, .vue-advanced-cropper__foreground {
  background-color: lighten(#01BBF4, 10%);
}

</style>
